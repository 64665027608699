<template>
    <div>
        <transition name="fade-opacity">
            <div v-show="active" id="spinner">
                <img src="@/assets/images/spinner.png" alt="" />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Spinner',
    props: ['active'],
}
</script>

<style lang="scss">
#spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.85;
    background-color: #001837;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s all;
    img {
        width: 143px;
        height: 143px;
    }
}
</style>
