<template>
    <l-marker ref="marker" :lat-lng="latLng" :icon="icon"></l-marker>
</template>

<script>
import { LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import L from 'leaflet'

if (typeof L.Marker.prototype._applyTransform == 'undefined') {
    // Adapted from https://github.com/bbecquet/Leaflet.RotatedMarker
    var proto_initIcon = L.Marker.prototype._initIcon
    var proto_setPos = L.Marker.prototype._setPos

    L.Marker.addInitHook(function() {
        var iconOptions = this.options.icon && this.options.icon.options
        var iconAnchor = iconOptions && this.options.icon.options.iconAnchor
        if (iconAnchor) {
            iconAnchor = iconAnchor[0] + 'px ' + iconAnchor[1] + 'px'
        }
        this.options.transformOrigin =
            this.options.transformOrigin || iconAnchor || 'center bottom'
        this.options.heading = this.options.heading || 0
        this.options.pitch = this.options.pitch || 0

        // Ensure marker keeps rotated during dragging
        this.on('drag', function(e) {
            e.target._applyTransform()
        })
    })

    L.Marker.include({
        _initIcon: function() {
            proto_initIcon.call(this)
        },

        _setPos: function(pos) {
            proto_setPos.call(this, pos)
            this._applyTransform()
        },

        _applyTransform: function() {
            if (this.options.heading) {
                this._icon.style[
                    L.DomUtil.TRANSFORM + 'Origin'
                ] = this.options.transformOrigin

                this._icon.style[
                    L.DomUtil.TRANSFORM
                ] += ` rotateZ(${this.options.heading}deg) rotateX(${this.options.pitch}deg) scaleX(${this.options.scaleX}) scaleY(${this.options.scaleY}) `
                /*let shadowSize = Math.round(20 * (this.options.pitch / 90 + 1))
                this._icon.style['filter'] = `drop-shadow(0px 0px ${shadowSize}px #222)`*/
            }
        },
    })
}

export default {
    name: 'RotatingMarker',
    components: {
        LMarker,
    },
    props: {
        heading: { type: Number, default: 0 },
        pitch: { type: Number, default: 0 },
        scaleX: { type: Number, default: 1 },
        scaleY: { type: Number, default: 1 },
        transformOrigin: { type: String, default: 'center bottom' },
        latLng: { type: [Object, Array], default: () => [0, 0] },
        icon: {
            type: Object,
            default: () => new Icon.Default(),
        },
    },
    watch: {
        heading: function() {
            this.$refs.marker.mapObject.options.heading = this.heading
            this.$refs.marker.mapObject.update()
        },
        pitch: function() {
            this.$refs.marker.mapObject.options.pitch = this.pitch
            this.$refs.marker.mapObject.update()
        },
        transformOrigin: function() {
            this.$refs.marker.mapObject.options.transformOrigin = this.transformOrigin
            this.$refs.marker.mapObject.update()
        },
        scaleX: function() {
            this.$refs.marker.mapObject.options.scaleX = this.scaleX
            this.$refs.marker.mapObject.update()
        },
        scaleY: function() {
            this.$refs.marker.mapObject.options.scaleY = this.scaleY
            this.$refs.marker.mapObject.update()
        },
    },
    mounted: function() {
        this.$refs.marker.mapObject.options.heading = this.heading
        this.$refs.marker.mapObject.options.pitch = this.pitch
        this.$refs.marker.mapObject.options.transformOrigin = this.transformOrigin
        this.$refs.marker.mapObject.options.scaleX = this.scaleX
        this.$refs.marker.mapObject.options.scaleY = this.scaleY
        this.$refs.marker.mapObject.update()
    },
}
</script>
