<template>
    <i18n :path="localeKey" tag="p">
        <template v-for="url in extract_urls(localeKey)" #[url]>
            <a :key="url" :href="$t(url)">
                {{ $t(url) }}
            </a>
        </template>
    </i18n>
</template>

<script>
export default {
    props: {
        localeKey: {
            type: String,
            default: null,
        },
    },
    methods: {
        extract_urls(key) {
            let matches = [...this.$t(key).matchAll(/\{(url.[a-z_]+)\}/g)]
            return matches.map((x) => x[1])
        },
    },
}
</script>
