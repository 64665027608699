function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

export default () => {
    const token = getCookie(process.env.VUE_APP_COOKIE_PREFIX + 'jwt')
    if (token == undefined) {
        return undefined
    }
    const claim = parseJwt(token)
    if (claim.email != undefined) {
        return claim.email
    } else {
        return undefined
    }
}
