<i18n>
{
  "nl-BE": {
    "enter_address": "Vul het adres hier in",
    "not_found": "Het adres staat niet in de lijst",
    "enter_parcel": "Enter parcel id here",
    "parcel_not_found": "The parcel id is not in the list"
  },
  "en-BE": {
    "enter_address": "Enter address here",
    "not_found": "The address is not in the list",
    "enter_parcel": "Enter parcel id here",
    "parcel_not_found": "The parcel id is not in the list"
  },
  "fr-BE": {
    "enter_address": "Entrer une adresse ici",
    "not_found": "L'adresse n'est pas dans la liste",
    "enter_parcel": "Enter parcel id here",
    "parcel_not_found": "The parcel id is not in the list"
  }
}
</i18n>

<template>
    <b-field expanded>
        <p class="control mb-1">
            <b-button
                type="submit"
                :size="size"
                class="button is-primary"
                icon-right="magnify"
                @click="$emit('selectedItem', selected)"
            ></b-button>
        </p>
        <b-autocomplete
            v-model="currentText"
            :size="size"
            :placeholder="placeholder || addressMessage"
            clearable
            expanded
            field="full_address"
            :data="data"
            :loading="isFetching"
            :disabled="readOnly"
            @typing="typing"
            @select="select"
        >
            <template slot-scope="props">
                <div class="media is-flex is-align-items-center">
                    <div class="media-left">
                        <b-icon icon="map-marker" type="is-primary" :size="size"></b-icon>
                    </div>
                    <div class="media-content">
                        {{ props.option.full_address }}
                    </div>
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
import debounce from 'lodash/debounce'

const mode_regex = /[^\d]{2}|[^0-9A-Za-z\/\-\_]/
export default {
    props: {
        size: {
            type: String,
            default: 'is-medium',
        },
        placeholder: {
            type: String,
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        searchMode: {
            type: String,
            default: 'address',
            validator: function (value) {
                return ['address', 'parcel', 'dual'].includes(value)
            },
        },
    },
    data() {
        return {
            currentText: '',
            selected: {},
            isFetching: false,
            data: [],
        }
    },
    computed: {
        addressMessage() {
            return this.$t(`autocomplete.enter_${this.searchMode}`)
        },
        notFoundItem() {
            if (this.detected_mode == 'address') {
                return {
                    building_id: null,
                    full_address: this.$t('autocomplete.address_not_found'),
                    type: 'not_found',
                }
            } else if (this.detected_mode == 'parcel') {
                return {
                    parcel_id: null,
                    full_address: this.$t('autocomplete.parcel_not_found'),
                    type: 'not_found',
                }
            } else {
                return {
                    parcel_id: null,
                    full_address: this.$t('autocomplete.dual_not_found'),
                    type: 'not_found',
                }
            }
        },
        detected_mode() {
            if (this.searchMode !== 'dual') {
                return this.searchMode
            } else if (mode_regex.test(this.currentText)) {
                return 'address'
            } else {
                return 'parcel'
            }
        },
    },
    methods: {
        typing: function (text) {
            this.currentText = text
            this.getAsyncData(text)
        },
        select: function (option) {
            this.selected = option
            this.$emit('selectedItem', option)
        },
        getAsyncData: debounce(function (name) {
            if (!name.length) {
                this.data = [this.notFoundItem]
                return
            }
            this.isFetching = true
            let params = { term: name }
            if (this.detected_mode === 'parcel') {
                params.expected_output = 'parcel_name'
            }
            this.$axios
                .get('/autocomplete', { params })
                .then(({ data }) => {
                    this.data = []
                    if (this.detected_mode !== 'parcel') {
                        data.results.forEach((item) =>
                            this.data.push({ ...item, type: 'address' })
                        )
                    } else {
                        data.results.forEach((item) =>
                            this.data.push({
                                parcel_id: item.parcel_name,
                                full_address: item.parcel_name,
                                type: 'parcel',
                            })
                        )
                    }
                    this.data.push(this.notFoundItem)
                })
                .catch((error) => {
                    this.data = [this.notFoundItem]
                    throw error
                })
                .finally(() => {
                    this.isFetching = false
                })
        }, 500),
    },
}
</script>

<style lang="scss" scoped>
.autocomplete-content {
    line-height: 0.8rem;
}
</style>
