<i18n>
{
  "nl-BE": {
    "msg": {
        "logout": "U bent nu uitgelogd.",
        "email-not-authorized": "Uw emailadres werd niet herkend. Gelieve opnieuw te proberen.",
        "email-sent_title": "Dank u!",
        "email-sent": "We stuurden u een toegangslink naar",
        "email-sent_message": "Als u geen link heeft ontvangen, kijk dan in uw spam folder.",
        "invalid-token": "De toegangslink is niet (langer) geldig. Voer uw emailadres in om een nieuwe link te ontvangen.",
        "session-expired": "Uw sessie is verlopen. Voer uw emailadres in om een nieuwe link te ontvangen."
    },
    "welcome": "Gelieve uw emailadres in te geven om toegang te krijgen tot de applicatie.",
    "login": "Log In",
    "back": "Terug",
    "email": "Uw email"
    
  },
  "en-BE": {
    "msg": {
        "logout": "You are successfully logged out.",
        "email-not-authorized": "We could not recognize your email. Please try again.",
        "email-sent_title": "Thank you!",
        "email-sent": "We sent an email with an access link to",
        "email-sent_message": "If you haven't received a link, please check your spam folder.",
        "invalid-token": "The link you used is invalid or has expired, please enter your email address to get a new link.",
        "session-expired": "Your session expired. Please enter your email address to get a new link."
    },
    "welcome": "Please enter your e-mail to access the application.",
    "login": "Log In",
    "back": "Back",
    "email": "Your email"
  },
  "fr-BE": {
    "msg": {
        "logout": "Vous êtes maintenant déconnecté.",
        "email-not-authorized": "Nous ne reconnaissons pas votre adresse email. Veuillez essayer à nouveau.",
        "email-sent_title": "Merci!",
        "email-sent": "Nous vous avons envoyé un lien par email à",
        "email-sent_message": "Si vous ne l'avez pas reçu, vérifiez votre dossier spam.",
        "invalid-token": "Le lien que vous avez utilisé est invalide ou a expiré, entrez votre adresse email pour obtenir un nouveau lien.",
        "session-expired": "Votre session a expiré. Entrez votre adresse email pour obtenir un nouveau lien."
    },
    "welcome": "Veuillez entrer votre adresse email pour accéder à l'application.",
    "login": "S'identifier",
    "back": "Retour",
    "email": "Votre email"
  }
}
</i18n>

<template>
    <div class="login is-relative">
        <div class="form-wrapper is-absolute">
            <div class="columns is-centered">
                <div
                    class="column is-5-fullhd is-6-desktop is-12-mobile is-9-tablet mb-6"
                >
                    <h3
                        class="is-centered has-text-centered has-text-weight-bold title is-size-2"
                    >
                        {{ title || $t('login') }}
                        <span></span>
                    </h3>
                    <div
                        v-if="$route.query.msg !== 'email-sent'"
                        class="has-text-centered"
                    >
                        {{ subtitle || $t('welcome') }}
                    </div>
                </div>
            </div>
            <div class="columns is-centered is-multiline is-mobile">
                <div
                    v-if="$route.query.msg !== 'email-sent'"
                    class="column is-4-fullhd is-5-desktop is-12-mobile is-9-tablet"
                >
                    <form :action="login_url" method="POST">
                        <b-field grouped position="is-centered" custom-class="is-medium">
                            <b-input
                                v-model="email"
                                size="is-medium"
                                name="email"
                                expanded
                                :placeholder="$t('email')"
                            ></b-input>
                            <p class="control is-flex is-justify-content-center">
                                <b-button
                                    size="is-medium "
                                    :label="$t('login')"
                                    native-type="submit"
                                    type="is-primary"
                                />
                            </p>
                        </b-field>
                    </form>
                </div>
            </div>
            <div class="columns is-centered is-multiline is-mobile">
                <div class="column is-4-fullhd is-5-desktop is-12-mobile is-9-tablet">
                    <!-- sent mail -->
                    <div
                        v-if="$route.query.msg === 'email-sent'"
                        class="container mt-4 is-flex is-align-items-center is-flex-direction-column"
                    >
                        <div class="card check-card">
                            <div class="card-content mt-3 mb-5">
                                <div class="is-flex is-justify-content-center">
                                    <span class="icon-box">
                                        <b-icon
                                            class="m-2"
                                            icon="check"
                                            size="is-medium"
                                            type="is-success"
                                            rounded
                                        ></b-icon>
                                    </span>
                                </div>
                                <div class="is-flex is-flex-direction-column">
                                    <p
                                        class="has-text-centered has-text-weight-semibold is-size-3 has-text-black pt-4 mt-1"
                                    >
                                        {{ $t('msg.email-sent_title') }}
                                    </p>
                                    <p class="has-text-centered has-text-grey py-4 my-1">
                                        {{ $t('msg.email-sent') }}
                                    </p>
                                    <p
                                        class="has-text-centered has-text-weight-medium has-text-black"
                                    >
                                        {{ $route.query.email }}
                                    </p>
                                    <p class="has-text-centered has-text-grey mt-4 mt-1">
                                        {{ $t('msg.email-sent_message') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="pt-5 mt-5 is-flex is-justify-content-center">
                            <b-button
                                tag="router-link"
                                :to="{ params: { action: 'login' } }"
                                type="is-primary"
                                icon-left="arrow-left"
                                size="is-medium"
                            >
                                {{ $t('back') }}
                            </b-button>
                        </div>
                    </div>
                    <!-- sent mail -->

                    <div
                        v-if="
                            $route.query.msg !== 'email-sent' &&
                            $route.query.msg !== undefined
                        "
                        class="is-fullwidth is-justify-content-center"
                    >
                        <b-notification
                            :type="'is-' + notificationType"
                            has-icon
                            aria-close-label="Close notification"
                            position="center"
                            :closable="false"
                        >
                            {{ $t('msg.' + $route.query.msg) }}
                        </b-notification>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '../plugins/utils'

export default {
    name: 'LogIn',
    props: {
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            email: '',
        }
    },
    computed: {
        login_url: function () {
            let redirect_query_param = this.$route.query.r
                ? `&r=${this.$route.query.r}`
                : ''
            return `${utils.urlJoin(process.env.VUE_APP_AUTH_API_URL, 'enter')}?lang=${
                this.$route.params.lang
            }${redirect_query_param}`
        },
        notificationType() {
            return {
                logout: 'success',
                'email-not-authorized': 'danger',
                'invalid-token': 'warning',
                'session-expired': 'warning',
            }[this.$route.query.msg]
        },
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Log In',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
}
</script>

<style lang="scss" scoped>
.login {
    background: url('./../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: calc(100vh - 53px);
    min-height: calc(100vh - 53px);
    width: 100%;
    .form-wrapper {
        left: 50%;
        top: 30%;
        width: 100%;
        transform: translate(-50%, -30%);
        padding: 0 1.5rem;
        section {
            min-width: 500px;
        }
    }
    .check-card {
        width: 450px;
        .icon-box {
            border-radius: 100%;
            background: rgba(82, 216, 96, 0.2);
        }
    }
}
</style>
