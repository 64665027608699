<template>
    <div id="home">
        <spinner :active="spinnerActive" />
        <div v-show="animation.content_show" class="content">
            <div class="logo">
                <img
                    src="@/assets/icons/rockestate_logo_aligned.svg"
                    alt=""
                    class="logo"
                />
            </div>
            <h1>Building catalog</h1>
            <div class="subtitle">
                Find out building characteristics based on open data
            </div>
            <div class="is-w100">
                <div class="columns is-mobile is-multiline pt-5 pb-1">
                    <div class="column is-full is-10-tablet is-12-mobile">
                        <auto-complete @selectedItem="selectedData($event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import AutoComplete from '@/shared/components/AutoComplete.vue'
import { mapMutations, mapActions } from 'vuex'

export default {
    name: 'Home',
    components: { Spinner, AutoComplete },
    data() {
        return {
            height: 0,
            spinnerActive: false,
            animation: {
                content_show: true,
            },
        }
    },
    computed: {},
    methods: {
        ...mapMutations(['UPDATE_ADDRESS', 'UPDATE_FEATURES']),
        selectedData(val) {
            this.UPDATE_ADDRESS({
                streetname: val.streetname,
                streetnumber: val.streetnumber,
                postalcode: val.postalcode,
                municipality: val.municipality,
            })
            this.UPDATE_FEATURES({ building_id: val.building_id })
            this.$router.push({
                name: 'action',
                params: {
                    lang: this.$route.params.lang,
                    action: val.level === '3' ? '3d' : '2d',
                },
                query: {
                    building_id: val.building_id,
                    streetname: val.streetname,
                    streetnumber: val.streetnumber,
                    postalcode: val.postalcode,
                    municipality: val.municipality,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
#home {
    min-height: calc(100vh - 4rem);
}
.search-section {
    width: 100%;
}
</style>
